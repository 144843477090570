import { React, useMemo } from "react";
import { LayerGroup, Pane, Polygon } from "react-leaflet";
import * as turf from "@turf/turf";
import LabelData from "./LabelData";

const PolygonDataLayer = (props) => {


    return (
        <Pane name={props.name}>
            <LayerGroup>
                {
                    props.data.features.map(layerData => {
                        const polygon = <PolygonData key={layerData.id}
                            layerProperties={layerData.properties[Object.keys(layerData.properties)[0]]}
                            geometry={layerData.geometry}
                            {...props} />;
                        return polygon;
                    })
                }
            </LayerGroup>
        </Pane>
    );
};

const PolygonData = (props) => {
    let points = null;
    let centroid = null;

    const layerAttribution = "";
    const defaultStyle = {
        opacity: 0.2,
        fillOpacity: 0.35,
        color: "rgba(0,0,0,0.9)",
        dashArray: "",
        lineCap: "butt",
        lineJoin: "miter",
        weight: 2,
        fill: true,
    };
    const configuredStyle = { ...defaultStyle, ...props.style };

    try {
        points = useMemo(() => props.geometry.coordinates[0][0].map((v) => { return v.slice().reverse(); }, []), [props.geometry]);
        centroid = useMemo(() => turf.centroid(turf.polygon(props.geometry.coordinates[0])).geometry.coordinates.reverse(), [props.geometry]);
    } catch (error) {
        //console.error(error);
        return null;
    }

    let dataList = null;
    if (props.layerProperties !== null) {
        dataList = props.layerProperties.split("/").map((s) => <li key={s}>{s}</li>);
    }

    return (
        <>
            <Polygon pathOptions={{ ...configuredStyle, fillOpacity: props.opacity }} positions={points} attribution={layerAttribution} interactive={props.toolTip} >
                {dataList !== null &&
                    <LabelData data={dataList}
                        center={centroid}
                        visibleDistance={props.labelVisibleZoomLevel}
                        textStyle={props.textStyle}
                        toolTip={props.toolTip} />
                }
            </Polygon>
        </>
    );
};

export default PolygonDataLayer;
