import React, { useState, useMemo, useRef } from "react";
import { Popup, CircleMarker, LayerGroup, Polygon, useMapEvents, Pane } from "react-leaflet";
import * as config from "../../config";
import { Dialog } from "@headlessui/react";
import VisibleWZoom from "./VisibleWZoom";

const PhotosLayer = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentPhotoURL, SetPhotoURL] = useState("");

    function openModal(url) {
        SetPhotoURL(url);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <VisibleWZoom visibleDistance={props.labelVisibleZoomLevel}>
            <LayerGroup>
                {
                    props.data.features.map((layerData) => <MarkerWithPopup key={layerData.id} layerData={layerData} openModal={openModal} />)
                }
            </LayerGroup>
            <PhotoModal isOpen={isOpen} onClose={closeModal} url={currentPhotoURL} ></PhotoModal>
        </VisibleWZoom>
    );
};

const MarkerWithPopup = (props) => {
    const [markerRadius, SetMarkerRadius] = useState(10);
    const [showLoS, SetShowLoS] = useState(false);
    const [imageLoaded, SetImageLoaded] = useState(false);

    const photoPoint = useMemo(() => props.layerData.geometry.coordinates[0][0][0].slice().reverse(), [props.layerData]);
    const photoLoS = useMemo(() => props.layerData.geometry.coordinates[0][0].map((v) => { return v.slice().reverse(); }, [props.layerData]));
    const photoFeatures = props.layerData;
    const description = `${photoFeatures.properties.Descrip}`;
    const ref = `${photoFeatures.properties.Ref}`;
    const source = `${photoFeatures.properties.Source}`;

    function style() {
        return {
            opacity: 0.9,
            color: "rgba(35, 35, 35, 0.8)",
            dashArray: "",
            lineCap: "butt",
            lineJoin: "miter",
            weight: 0.4,
            fill: true,
            fillOpacity: 0.8,
            fillColor: "rgba(37, 143, 181, 1)",
            interactive: true,
        };
    }

    function buildImageUrl(preset) {
        return `${config.qgis_server_url.img}insecure/${preset}/plain/local://${photoFeatures.properties.Photos}`;
    }

    return (
        <>
            <CircleMarker
                pane="photoPane"
                center={photoPoint}
                pathOptions={{ opacity: 0, fillOpacity: 0 }}
                radius={20}
                bubblingMouseEvents={false}
                eventHandlers={{
                    mouseover: () => { SetShowLoS(true); },
                    mouseout: () => { SetShowLoS(false); },
                    popupopen: () => { SetMarkerRadius(2000); },
                    popupclose: () => { SetMarkerRadius(10); },
                }
                }>
                <Popup offset={[0, -16]} maxWidth={"auto"} closeButton={false} pane="infoPane" >
                    <div id="popupContent" className={"flex flex-col justify-center" + ((imageLoaded ? "" : "hidden"))}>
                        <img className="cursor-move max-w-[40vw] max-h-[80vh] mx-auto" src={buildImageUrl("default")} alt={description} onLoad={() => { SetImageLoaded(true); }} onClick={() => { props.openModal(buildImageUrl("default")); }} />
                        <div className="text-center">
                            <p className="font-bold">
                                {description}
                            </p>
                            <div className="">
                                <span>
                                    Author: {ref}
                                </span>
                                <br />
                                <span>
                                    Source: {source}
                                </span>
                            </div>
                        </div>
                    </div>
                </Popup>
                <CircleMarker
                    pane="photoPane"
                    center={photoPoint}
                    pathOptions={{ opacity: 0, fillOpacity: 0 }}
                    radius={markerRadius}
                    bubblingMouseEvents={false}
                />
                <CircleMarker
                    pane="photoPane"
                    center={photoPoint}
                    pathOptions={style()}
                    radius={10}
                    bubblingMouseEvents={true}
                />
            </CircleMarker>
            <PhotoLoS hidden={!showLoS} points={photoLoS} />
        </>
    );
};

const PhotoLoS = (props) => {

    function style() {
        return {
            opacity: 0.6,
            color: "rgba(35, 35, 35, 0.8)",
            dashArray: "",
            lineCap: "butt",
            lineJoin: "miter",
            weight: 0.4,
            fill: true,
            fillOpacity: 0.3,
            fillColor: "rgba(37, 143, 181, 1)",
            interactive: false,
        };
    }

    if (props.hidden !== true) {
        return (
            <Polygon pathOptions={style()} positions={props.points} />
        );
    }
    else {
        return null;
    }
};

const PhotoModal = (props) => {

    let initialFocus = useRef();

    return (
        <Dialog initialFocus={initialFocus} open={props.isOpen} as="div" className="fixed inset-0 z-[9999]" onClose={props.onClose}>
            <div className="h-full bg-black bg-opacity-25" />
            <div className="fixed inset-0 flex flex-col h-full items-center portrait:justify-center xs:justify-center overflow-y-auto ml-auto mr-auto">
                <div className="h-[80vh] landscape:max-w-[80]">
                    <Dialog.Panel className="max-w-max max-h-max transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <img className="cursor-move" ref={initialFocus} src={props.url} onClick={props.onClose} />
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};



export default PhotosLayer;